<template>
  <!-- 标点map -->
  <div class="initMaped">
    <div id="screenMap" class="screenMap"></div>
  </div>
</template>
<script>
import polygonPath from "./zyx.json";
// import AMapLoader from "@amap/amap-jsapi-loader";
export default {
  name: "initMarkerMap",
  props: {
    addressList: {
      type: Array,
      // required: true,
      default: () => {
        return [];
      },
    },
  },
  watch: {
    addressList(newValue, oldValue) {
      this.addMapCase();
    },
  },
  data() {
    return {
      iconGreen: require("@/assets/smartStorgeScreen/point_green.png"),
      iconYellow: require("@/assets/smartStorgeScreen/point_yellow.png"),
      mapMain: require("@/assets/smartStorgeScreen/map_main.png"),
      marker: [],
      map: null,
      setData: "",
      content: [],
      markList: [],
    };
  },
  created() {},
  mounted() {},
  methods: {
    //   销毁地图
    disposeMap() {
      this.map.dispose();
    },

    // 加载高德地图
    addMapCase() {
      // AMapLoader.load({
      //               // 替换为你的高德地图API Key
      //               key: 'ec9cb54feced4a6f72021702ee8ca907',
      //               // version: '2.0',
      //               // plugins: ['AMap.MoveAnimation','AMap.Geolocation','AMap.MouseTool'],
      //           })
      //           .then((AMap) => {
      var satellite = new AMap.TileLayer.Satellite();
      // var roadNet = new AMap.TileLayer.RoadNet();
      this.map = new AMap.Map("screenMap", {
        resizeEnable: true,
        // center: [109.734104,38.28576],
        // center: [116.397428, 39.90923],
        center: [108.53, 32.52],
        zooms: [4, 18], //设置地图级别范围
        zoom: 14,
        // viewMode: "3D", //设置地图模式
        // mapStyle: "amap://styles/fresh",
        layers: [satellite],
        // pitch: 50,
      });
      this.map.clearMap();


      console.log(this.addressList, "@@@@@@@@@@@@");


      // 内发光18px  #16aad5    渐变透明度1到0
      var polygon = new AMap.Polygon({
        path: polygonPath.features[0].geometry.coordinates, //路径
        fillColor: "#18C7DE", //多边形填充颜色
        fillOpacity: 0.7, // 填充透明度
        // fillColor: ['#FF0000'，'#00FF00'],
        strokeWeight: 3, //线条宽度，默认为 2
        strokeColor: "#ace7ff", //线条颜色
        // strokeColor: "#FFFFFF", //线条颜色
        // lineJoin: 'round', // 线与线之间的角度
        // lineCap: 'round', // 线的两端的样式
        zIndex: 50, // 图层层级
        height: 10,
        wallColor: "#F00",
      });
      polygon.setMap(this.map);
      // //多边形 Polygon对象添加到 Map
      // this.map.add(polygon);
      //将覆盖物调整到合适视野
      this.map.setFitView([polygon]);

      // this.map.remove(this.markers) // 清除所有的marker标记
      this.marker = []; // 清空

      // 添加marker标记
      this.addressList.forEach((item, index) => {
        console.log(item, "循环中……");
        let location = item.location.split(",");
        console.log(location, "==========", item);
        const marker = new AMap.Marker({
          position: new AMap.LngLat(location[1], location[0]),
          offset: new AMap.Pixel(0, -10),
          icon: this.iconYellow, //添加 icon 图标 URL
        });

        marker.lxr = item.lxr || "";
        marker.lxfs = item.lxfs || "";
        marker.address = item.address || "";
        marker.name = item.name || "";

        //创建点标记的点击事件
        marker.on("click", (e) => {
          this.getInfo(e);
        });

        this.map.add(marker);
      });
      // map.add(_this.marker) // 动态添加数据

      // })
    },

    getInfo(e) {
      // 创建 infoWindow 实例
      let infoWindow = new AMap.InfoWindow({
        offset: new AMap.Pixel(0, -20),
        content: ` 
          <div class="infoBox">
            <div class="info_title">${e.target.name}</div>
            <div class="info_item">
            联系人： ${e.target.lxr}
              </div>
              <div class="info_item">
              联系电话：<span>${e.target.lxfs}</span>
              </div>
              <div class="info_item">
              联系地址：<span style="color:#0d829a">${e.target.address}</span>
              </div>
              </div>
            `, //传入 dom 对象，或者 html 字符串
      });
      // 打开信息窗体
      infoWindow.open(this.map);
      infoWindow.open(this.map, [e.lnglat.lng, e.lnglat.lat]);
      // 关闭信息窗体
      // infoWindow.close();
      // this.$emit('markerClick',e.target?.deviceNumber||this.markList[0].icon.deviceNumber)
    },
  },
};
</script>

<style lang="less">
.initMaped {
  width: 100%;
  height: 100% !important;
  position: relative;
}
.initMaped .amap-icon img {
  width: 2vw;
}

#screenMap ::v-deep .amap-layer polyline {
  filter: drop-shadow(0 0 20px rgba(255, 0, 0, 0.8));
}

.meng_flow {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url("~@/assets/smartStorgeScreen/bgc_main.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: 2;
}
.initMaped .screenMap {
  position: relative;
  top: 0;
  width: 100%;
  height: 100% !important;
}
.bottom-center .amap-info-sharp {
  display: none !important;
}

.amap-info-close {
  color: #55e2eb !important;
}
.amap-info-content {
  padding: 0 !important;
  box-sizing: border-box;
  background: transparent !important;
  display: flex;
  position: relative;
  .titleLeft {
    position: absolute;
    top: 1.58vh;
    left: 0.94vw;
    font-size: 1.48vh;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
  .titleRight {
    left: auto !important;
    top: 0.8vh;
    right: 0.94vw;
    font-size: 1.11vh;
  }
  // 窗体样式
  .imgBox {
    width: 5.94vw;
    height: 10.56vh;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .infoBox {
    height: 7vw;
    display: flex;
    // flex-direction: column;
    // justify-content: space-between;
    width: 14vw;
    flex-wrap: wrap;
    background-image: url("~@/assets/smartStorgeScreen/infowindow_bgc.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 0.5vw 1vw;
    box-sizing: border-box;
    .info_title {
      font-size: 0.9vw;
      width: 100%;
      font-weight: bold;
      margin-bottom: 1vh;
      color: #55e2eb;
    }
    .info_item {
      font-size: 0.7vw;
      width: 100%;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #dbf3ff;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 0.5vw;
      // .colorOn {
      //   color: #80ee03 !important;
      // }
    }
  }
}
</style>
