<template>
  <div class="app-container">
    <CityMap :addressList="treeData" />
    <div class="leftBgc"></div>
    <div class="rightBgc"></div>
    <div class="bottomBgc"></div> 

    <div class="titleBox flex_row">

      <div class="title_left">{{ currentDay }} {{ dayOfWeekText }}</div>
      <div class="titleBig">智慧仓储一张图</div>
      <div class="flex_row title_right">
        <div>{{ text }}</div>
        <div style="margin: 0 15px">{{ temp }} ℃</div>
        <div>{{ windDir }}{{ windScale }}级</div>
      </div>
    </div>

    <div class="content_left">
      <div style="padding: 30px 0 0 20px; box-sizing: border-box; height: 8%;">
        <span class="headerLeft" @click="backIndex">
        返回首页
      </span>
      </div>

      <div class="sec_box" style="height: 30%">
        <div class="sec_title flex_row" style="padding: 1% 3% 1% 10%;">
          <div class="sec_title_txt" style="padding-left: 15px;">库容量分析</div>
          <div class="title_r_ctrol">
            <el-select v-model="regionId" placeholder="请选择">
              <el-option v-for="item in treeData" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="sec_cont">
          <div class="pie_3d_box">
            <PieGl :pieData="pieData" @getHeatComplain="getHeatComplain" />

          </div>
        </div>
      </div>

      <div class="sec_box" style="height: 30%">
        <div class="sec_title">
          <div class="sec_title_txt">出入库分析</div>
        </div>
        <div class="sec_cont" id="barTopside"></div>
      </div>

      <div class="sec_box" style="height: 30%">
        <div class="sec_title">
          <div class="sec_title_txt">库存明细分析</div>
        </div>
        <div class="sec_cont" id="heatComplaintRank"></div>
      </div>
    </div>
    <div class="content_center flex_row">
      <div class="tab_item" v-for="item in tabList" :key="item.key">
        <div class="tab_item_label">{{ item.label }}</div>
        <div class="tab_item_val">
          <div class="val_text">{{ coolTypeSum[item.key] || "-" }}</div>
        </div>
      </div>
    </div>

    <div class="content_right">
      <div style="padding: 30px 0 0 20px; box-sizing: border-box; height: 8%"></div>
      <div class="sec_box" style="height: 30%">
        <div class="sec_title">
          <div class="sec_title_txt">仓储告警汇总</div>
        </div>
        <div class="sec_cont top_r">
          <div class="flex_row total_line">
            <div class="total_line_label">累计报警</div>
            <div class="total_line_num">{{ warningMess.totalNum }} 次</div>
          </div>
          <div class="flex_row options_line">
            <div class="options_line_item flex_row" :class="{ 'time_active': i.num == warningTime }"
              v-for="(i, index) in timeOptions" :key="index" @click="warningTimeChange(i)">
              {{ i.label }}
            </div>
          </div>
          <div class="flex_row cont_sec">
            <div class="cont_sec_left flex_col">
              <img src="~@/assets/smartStorgeScreen/car.png" />
              <div style="height: 23%;">到期报警</div>
              <div class="cont_sec_num">
                <span style="font-size: 20px;">{{ warningMess.htdqs }}</span>次
              </div>
            </div>
            <div class="cont_sec_right flex_col">
              <img src="~@/assets/smartStorgeScreen/house.png" />
              <div style="height: 23%;">仓储容量报警</div>
              <div class="cont_sec_num">
                <span style="font-size: 20px;">{{ warningMess.sylv }}</span>次
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="sec_box" style="height: 30%">
        <div class="sec_title">
          <div class="sec_title_txt">每日订单明细</div>
        </div>
        <div class="sec_cont table_list" ref="tableBox">
          <el-table :data="tableData" :max-height="tableH" ref="scroll_Table" :row-class-name="tableRowClassName"
            @mouseenter.native="autoScroll(true)" @mouseleave.native="autoScroll(false)"
            style="width: 100%; height: 100%" :header-cell-style="() => {
              return { 'text-align': 'center' };
            }
              " :cell-class-name="() => {
                return 'table_cell_center';
              }
                ">
            <el-table-column prop="crklx" label="类型" width="60">
            </el-table-column>
            <el-table-column prop="cpmc" label="产品名称" width="80">
            </el-table-column>
            <el-table-column prop="capacity" label="数量" width="80">
            </el-table-column>
            <el-table-column prop="showdqrq" label="到期日期">
            </el-table-column>
            <el-table-column prop="gnmc" label="租户名称" width="80">
            </el-table-column>
          </el-table>
        </div>
      </div>

      <div class="sec_box" style="height: 30%">
        <div class="sec_title">
          <div class="sec_title_txt">租赁订单统计</div>
        </div>
        <div class="sec_cont flex_col">
          <div class="flex_row options_echarts_data">
            <div class="options_echarts_data_item flex_col" :class="{ 'type_selected': orderType == item.key }"
              v-for="(item, index) in typeOptions" :key="index" @click="orderTypeChange(item)">{{
                item.label }}
            </div>
          </div>
          <div style="width: 100%;height: 85%;" id="orderline"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PieGl from "./modal/pieGl";
import CityMap from "./modal/cityMap";

import {
  barTwosideOptions,
  barTopsideOptions,
  chartOptions,
} from "./modal/echarts";
// import { orderCnt } from "@/api/heatingRegulation/heatingRegulationHomePage";
import { mapGetters } from "vuex";
import moment from "moment";
// import Echarts from "@/components/Echarts/index";
// import SeamlessScroll from "@/components/SeamlessScroll";
// import { getRoomTemp7Day, getRoomTempRate } from "@/api/pageSummary/roomTempMonitor";
let heatComplaintRankEcharts = null;
let barTopsideEcharts = null;
let orderEcharts = null;
let heatComplainGl = null;
export default {
  name: "smartStorgeScreen",
  components: {
    CityMap,
    // Echarts,
    // SeamlessScroll,
    PieGl,
  },

  data() {
    return {
      tableData: [],
      text: "",
      temp: "",
      windDir: "",
      windScale: "",
      weatherKey: "5a0bc17ca9a643dc84fee66d458770ce",

      mapDataList: [],

      mapIndex: "1",
      tabList: [
        {
          label: "冷库类型",
          key: "type",
        },
        {
          label: "容量",
          key: "total",
        },
        {
          label: "已使用容量",
          key: "usedCapacity",
        },
        {
          label: "剩余容量",
          key: "unusedCapacity",
        },
        {
          label: "累计服务用户",
          key: "gnNum",
        },
        {
          label: "订单总数量",
          key: "inwsNum",
        },
        {
          label: "订单总金额",
          key: "inwsPrice",
        },
      ],
      timeOptions: [
        {
          label: "最近一周",
          num: 7,
        },
        {
          label: "最近一个月",
          num: 30,
        },
        {
          label: "最近三个月",
          num: 90,
        },
        {
          label: "全部",
          num: null,
        },
      ],
      warningTime: 7,
      typeOptions: [
        {
          label: "苹果",
          key: "apple",
        },
        {
          label: "梨",
          key: "pear",
        },
        {
          label: "核桃",
          key: "walnut",
        },
        {
          label: "其他",
          key: "other",
        },
      ],
      orderType: "apple",
      linechartsData: null,
      tableH: 350,

      tableTimer: null,

      coolTypeSum: {}, // 类型统计
      pieData: {}, // 饼图数据
      warningMess: {},
      treeData: [], // 选择库
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      regionId: "",

      currentDay: moment().format("YYYY/MM/DD"), // 日期
      dayOfWeekText: "", // 星期几
    };
  },
  computed: {
    ...mapGetters(["cityItem"]),
  },
  created() {},
  watch: {
    regionId: {
      async handler(newVal, oldVal) {
        console.log(newVal, "aaaaaaaaaa");
        if (newVal) {
          this.regionId = newVal;
          this.getMess();
        }
      },
    },
  },
  mounted() {
    this.$store.commit("SET_MENUFLAG", false); //显示菜单
    this.$store.commit("SET_ICONFLAG", true);
    // console.log(this.$refs.tableBox.scrollHeight, "11111111")
    // this.tableH = this.$refs.tableBox.scrollHeight
    this.init();
    this.getDay();
    this.getWeather();
  },
  activated() {
    this.$store.commit("SET_MENUFLAG", false); //显示菜单
    this.$store.commit("SET_ICONFLAG", true);
  },
  beforeDestroy() {
    // 清除定时器
    if (this.tableTimer) {
      clearInterval(this.tableTimer);
    }
  },
  methods: {
    //返回首页
    backIndex(){
      this.$router.push(window.localStorage.getItem('screenRouter'))
    },
    // 获取天气
    getWeather(location) {
      this.$axios({
        method: "GET",
        url: "https://geoapi.qweather.com/v2/city/lookup",
        params: { adm: "陕西省", location: "安康市", key: this.weatherKey },
      }).then((res) => {
        if (res.status == 200) {
        
          let { location } = res.data;
          this.$axios({
            method: "GET",
            url: "https://devapi.qweather.com/v7/weather/now",
            params: { location: location[0].id, key: this.weatherKey },
          }).then((res) => {
            if (res.status == 200) {
              this.weathers = res.data.now;
              this.text = res.data.now.text;
              this.temp = res.data.now.temp;
              this.windDir = res.data.now.windDir;
              this.windScale = res.data.now.windScale;
            }
          });
        }
      });
    },

    // 获取星期几
    getDay() {
      // 获取当前是星期几的数值
      let dayOfWeek = moment().day();

      // 转换为星期几的文本表示
      let dayOfWeekText;
      switch (dayOfWeek) {
        case 0:
          dayOfWeekText = "星期日";
          break;
        case 1:
          dayOfWeekText = "星期一";
          break;
        case 2:
          dayOfWeekText = "星期二";
          break;
        case 3:
          dayOfWeekText = "星期三";
          break;
        case 4:
          dayOfWeekText = "星期四";
          break;
        case 5:
          dayOfWeekText = "星期五";
          break;
        case 6:
          dayOfWeekText = "星期六";
          break;
      }

      console.log(dayOfWeekText);
      this.dayOfWeekText = dayOfWeekText;
    },
    // 报警时间选择
    warningTimeChange(data) {
      console.log(data, "####报警时间选择#####");
      this.warningTime = data.num;
      this.getWarningMess();
    },
    // 订单统计选择
    orderTypeChange(data) {
      console.log(data, "000订单统计00");
      this.orderType = data.key;
    },

    // 获取热源类型实例
    getHeatComplain(myChart) {
      heatComplainGl = myChart;
    },

    init() {
      // 列表信息
      this.$get(`/pro09/lengku/tree`, {}).then((res) => {
        console.log(res, "列表信息");
        if (res.data.state == "success") {
          this.treeData = res.data.data || [];
          if (this.treeData.length > 0) {
            console.log(this.treeData);
            this.regionId = this.treeData[0].id;
            this.$forceUpdate();
            // this.getMess()
          } else {
            this.$message.error("请先创建冷库");
          }
        } else {
        }
      });
    },
    async getMess() {
      // 冷库类型统计
      this.$get(`/dptj09/lklxtj/${this.regionId}`, {}).then((res) => {
        console.log(res, "冷库类型统计");
        if (res.data.state == "success") {
          this.coolTypeSum = res.data.data;
        } else {
          this.coolTypeSum = {};
        }
      });
      // 出入库分析
      this.$get(`/dptj09/crkfx/${this.regionId}`, {}).then((res) => {
        console.log(res, "出入库分析");
        if (res.data.state == "success") {
          let data = res.data.data || [];
          let barTopsideChart = this.$echarts.init(
            document.getElementById("barTopside")
          );
          barTopsideOptions(barTopsideChart, data);
          barTopsideEcharts = barTopsideChart;
        } else {
        }
      });
      // 库存容量分析
      this.$get(`/dptj09/lkrlfx/${this.regionId}`, {}).then((res) => {
        console.log(res, "库存容量分析");
        if (res.data.state == "success") {
          this.pieData = res.data.data;
        } else {
        }
      });
      // 库存明细分析
      this.$get(`/dptj09/kcmxfx/${this.regionId}`, {}).then((res) => {
        console.log(res, "库存明细分析");
        if (res.data.state == "success") {
          let detailData = res.data.data || [];

          let myChart = this.$echarts.init(
            document.getElementById("heatComplaintRank")
          );
          barTwosideOptions(myChart, detailData);
          heatComplaintRankEcharts = myChart;
        } else {
        }
      });
      // 当日订单明细
      this.$get(`/dptj09/drddmx/${this.regionId}`, {}).then((res) => {
        console.log(res, "当日订单明细");
        if (res.data.state == "success") {
          res.data.data.forEach((item) => {
            item.showdqrq = item.dqrq.split(" ")[0];
          });
          this.tableData = res.data.data || [];
          if (this.tableTimer) {
            clearInterval(this.scrolltimer);
            clearTimeout(this.tableTimer);
          }
          this.tableTimer = setTimeout(() => {
            this.autoScroll(false);
          }, 1000);
        } else {
        }
      });

      // 报警信息
      this.getWarningMess();

      // 租赁订单统计
      this.$get(`/dptj09/zlddtj/${this.regionId}`, {}).then((res) => {
        console.log(res, "租赁订单统计");
        if (res.data.state == "success") {
          let orderData = res.data.data || [];
          let orderChart = this.$echarts.init(
            document.getElementById("orderline")
          );
          chartOptions(orderChart, orderData, "apple");
          orderEcharts = orderChart;
          // this.linechartsData = chartOptions(res.data || []);
        } else {
        }
      });
    },

    // 报警信息
    getWarningMess() {
      this.$get(`/dptj09/bjxx/${this.regionId}/${this.warningTime}`, {}).then(
        (res) => {
          console.log(res, "报警信息");
          if (res.data.state == "success") {
            let mess = res.data.data || {};
            // mess.sylvPrecent = (mess.sylv * 100).toFixed(0)
            // mess.totalNumPrecent = (mess.totalNum * 100).toFixed(0)
            mess.totalNum = mess.htdqs + mess.sylv;
            this.warningMess = mess;
          } else {
          }
        }
      );
    },

    // 设置自动滚动
    autoScroll(stop) {
      const table = this.$refs.scroll_Table;
      // 拿到表格中承载数据的div元素
      const divData = table.$refs.bodyWrapper;
      // 拿到元素后，对元素进行定时增加距离顶部距离，实现滚动效果(此配置为每100毫秒移动1像素)
      if (stop) {
        //再通过事件监听，监听到 组件销毁 后，再执行关闭计时器。
        window.clearInterval(this.scrolltimer);
      } else {
        this.scrolltimer = window.setInterval(() => {
          // 元素自增距离顶部1像素
          divData.scrollTop += 1;
          // 判断元素是否滚动到底部(可视高度+距离顶部=整个高度)
          if (
            divData.clientHeight + divData.scrollTop ==
            divData.scrollHeight
          ) {
            // 重置table距离顶部距离
            divData.scrollTop = 0;
            // 重置table距离顶部距离。值=(滚动到底部时，距离顶部的大小) - 整个高度/2
            // divData.scrollTop = divData.scrollTop - divData.scrollHeight / 2
          }
        }, 150); // 滚动速度
      }
    },

    // 获取最大值
    getMaxVal(data) {
      return data.reduce((maxItem, currentItem) => {
        return Number(maxItem.stationCount) > Number(currentItem.stationCount)
          ? maxItem
          : currentItem;
      }, data[0]);
    },

    // 获取有效值
    effectiveVal(data, name) {
      if (this.isValidObj(data)) {
        return data[name] || data[name] == 0 ? data[name] : "";
      }
      return "";
    },

    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 === 1) {
        return "warning-row";
      } else if (rowIndex % 2 === 0) {
        return "success-row";
      }
      return "";
    },
  },
};
</script>

<style lang='scss' src="./modal/index.scss" scoped></style>